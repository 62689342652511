import React, { useState, useEffect } from 'react';
import { Accordion, Row, Col, Button, Modal, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons'; // Import Bootstrap icons
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import carData from './ssData/dropdownData/carData.json';
import defaultData from './ssData/dropdownData/defaultData.json';
import './styles/filters.scss';

function FiltersForm({ filterData }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [filterGroupId, setFilterGroupId] = useState(null);

    const [filters, setFilters] = useState({
        filterGroupName: 'Filter',
        category: "0",
        model: "0",
        priceMin: null,
        priceMax: null,
        yearMin: '-',
        yearMax: '-',
        volumeMin: null,
        volumeMax: null,
        engineType: "0",
        gearBox: "0",
        bodyType: "0",
        color: "0",
        area: "0",
        dealType: "0",
        checkup: null,
        totalRun: null
    });

    const generateYears = (startYear, endYear) => {
        const years = [];
        for (let year = startYear; year <= endYear; year++) {
            years.push(year);
        }
        return years;
    };

    const yearsMin = filters.yearMax === '-' ? generateYears(1900, new Date().getFullYear()).concat('-') : generateYears(1900, filters.yearMax).concat('-');
    const yearsMax = filters.yearMin === '-' ? generateYears(1900, new Date().getFullYear()).concat('-') : generateYears(filters.yearMin, new Date().getFullYear()).concat('-');

    const [showCheckUpDate, setShowCheckUpDate] = useState(false);

    const generateCheckUpMonths = () => {
        const currentDate = new Date();
        const endDate = new Date(currentDate);
        endDate.setFullYear(endDate.getFullYear() + 2);
        endDate.setMonth(endDate.getMonth() + 1);

        const checkUpMonths = [];

        while (currentDate < endDate) {
            const monthText = currentDate.toLocaleDateString('default', { month: 'short', year: 'numeric' });
            const monthValue = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getFullYear()}`;
            checkUpMonths.push({ text: monthText, value: monthValue });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return checkUpMonths;
    };

    useEffect(() => {
        if (filterData) {
            setFilters({
                filterGroupName: filterData.filterGroupName || 'Filter',
                category: filterData.category || "0",
                model: filterData.model || "0",
                priceMin: filterData.priceMin || null,
                priceMax: filterData.priceMax || null,
                yearMin: filterData.yearMin || '-',
                yearMax: filterData.yearMax || '-',
                volumeMin: filterData.volumeMin || null,
                volumeMax: filterData.volumeMax || null,
                engineType: filterData.engineType || "0",
                gearBox: filterData.gearBox || "0",
                bodyType: filterData.bodyType || "0",
                color: filterData.color || "0",
                area: filterData.area || "0",
                dealType: filterData.dealType || "0",
                checkup: filterData.checkup || null,
                totalRun: filterData.totalRun || null
            });
            setFilterGroupId(filterData.filterGroupId);
            setShowCheckUpDate(!!filterData.checkup);
        }
    }, [filterData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevFilters => ({
            ...prevFilters, 
            [name]: value === "0" ? null : value,  // Convert "0" to null
            ...(name === 'category' && value === "0" && { model: null }) // If category is "All", clear model
        }));
    };
    

    const handleCheckUpChange = (event) => {
        const isChecked = event.target.checked;
        setShowCheckUpDate(isChecked);

        setFilters(prevFilters => ({
            ...prevFilters,
            checkup: isChecked ? (prevFilters.checkup || getCurrentMonth()) : null
        }));
    };

    const getCurrentMonth = () => {
        const currentDate = new Date();
        return `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getFullYear()}`;
    };

    const handleValidation = async () => {
        try {
            setIsSaving(true);
            const url = filterGroupId == null ? '/filters/new' : `/filters/update/${filterGroupId}`;
            const method = filterGroupId == null ? 'post' : 'put';
            const response = await axios[method](url, { filters }, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setErrorMessage(false);
                window.location.reload();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Unexpected error occurred during save.');
            }
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        if (filterGroupId != null) {
            try {
                await axios.delete(`/filters/delete/${filterGroupId}`, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setShowDeleteModal(false);
                setIsDeleting(false);
                window.location.reload();
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div>
            <div className="container-fluid" id='filters-container'>
                <form>
                    <Accordion.Item eventKey={filterGroupId == null ? 0 : filterGroupId}>
                        <Accordion.Header className="custom-accordion-header">{filters.filterGroupName}</Accordion.Header>
                        <Accordion.Body>

                            {/* Filter Name */}
                            <div className="input-container">
                                <label htmlFor="filterGroupNameInput" className="floating-label">Filter name:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Give your filter group a name so you can easily identify it later. For example, you can name it 'Family Cars' or 'SUVs'.</Tooltip>}
                                    >
                                       <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                <Form.Control 
                                      type="text" 
                                      placeholder="My custom filter name" 
                                      name='filterGroupName' 
                                      value={filters.filterGroupName} 
                                      onChange={handleChange} 
                                      maxLength={40} 
                                      required 
                                    />
                                </div>
                            </div>

                            {/* Car Brand */}
                            <div className="input-container">
                                <label htmlFor="brandSelect" className="floating-label">Car brand:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Choose the brand (make) of the car you're interested in, such as Toyota, BMW, or Ford.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <Form.Select name='category' value={filters.category} onChange={handleChange}>
                                        {Object.entries(carData.category).map(([key, { display, value }]) => (
                                            <option key={key} value={value}>{display}</option>
                                        ))}
                                    </Form.Select>
                                    
                                </div>
                            </div>

                            {/* Model */}
                            {filters.category && filters.category !== "0" && (
                                <div className="input-container">
                                    <label htmlFor="modelSelect" className="floating-label">Model:</label>
                                    <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Select the specific model of the car you want, like 'Corolla' for Toyota or 'Civic' for Honda.</Tooltip>}
                                        >
                                            <InfoCircle className="ms-2 info-icon" />
                                        </OverlayTrigger>
                                    <div className="input-group">
                                        <Form.Select name='model' value={filters.model} onChange={handleChange}>
                                            {Object.entries(carData.category[filters.category].models).map(([modelValue, modelDisplay]) => (
                                                <option key={modelValue} value={modelValue}>
                                                    {modelDisplay}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        
                                    </div>
                                </div>
                            )}

                            {/* Price */}
                            <div className="input-container">
                                <label htmlFor="priceInput" className="floating-label">Price:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Set the price range you're looking for. The 'From' field is the minimum price, and the 'To' field is the maximum price you're willing to pay.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <FloatingLabel controlId="floatingPriceFrom" label="From">
                                        <Form.Control id='cool-control-left' type="number" placeholder="0" name='priceMin' value={filters.priceMin} onChange={handleChange} />
                                    </FloatingLabel>
                                    <span className="input-group-text">-</span>
                                    <FloatingLabel controlId="floatingPriceTo" label="To">
                                        <Form.Control id='cool-control-right' type="number" placeholder="0" name='priceMax' value={filters.priceMax} onChange={handleChange} />
                                    </FloatingLabel>
                                    
                                </div>
                            </div>

                            {/* Year */}
                            <div className="input-container">
                                <label htmlFor="yearInput" className="floating-label">Year:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Choose the year range of the car. The 'From' field is the oldest year you're okay with, and the 'To' field is the newest year.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <FloatingLabel controlId="floatingYearFrom" label="From">
                                        <Form.Select id='cool-control-left' name='yearMin' value={filters.yearMin} onChange={handleChange}>
                                            {yearsMin.map((year) => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                    <span className="input-group-text">-</span>
                                    <FloatingLabel controlId="floatingYearTo" label="To">
                                        <Form.Select id='cool-control-right' name='yearMax' value={filters.yearMax} onChange={handleChange}>
                                            {yearsMax.map((year) => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                    
                                </div>
                            </div>

                            {/* Volume */}
                            <div className="input-container">
                                <label htmlFor="volumeInput" className="floating-label">Engine volume:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Enter the engine volume range (measured in liters). 'From' is the smallest engine size, and 'To' is the largest. For example, 1.5L to 3.0L.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <FloatingLabel controlId="floatingVolumeFrom" label="From">
                                        <Form.Control id='cool-control-left' type="number" step=".1" placeholder="0" name='volumeMin' value={filters.volumeMin} onChange={handleChange} />
                                    </FloatingLabel>
                                    <span className="input-group-text">-</span>
                                    <FloatingLabel controlId="floatingVolumeTo" label="To">
                                        <Form.Control id='cool-control-right' type="number" step=".1" placeholder="0" name='volumeMax' value={filters.volumeMax} onChange={handleChange} />
                                    </FloatingLabel>
                                    
                                </div>
                            </div>

                            {/* Engine Type */}
                            <div className="input-container">
                                <label htmlFor="engineSelect" className="floating-label">Engine:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Pick the type of engine the car uses, like gasoline, diesel, hybrid, or electric.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <Form.Select name='engineType' value={filters.engineType} onChange={handleChange}>
                                        {Object.entries(carData.engine).map(([text, value]) => (
                                            <option key={value} value={value}>{text}</option>
                                        ))}
                                    </Form.Select>
                                    
                                </div>
                            </div>

                            {/* Gearbox */}
                            <div className="input-container">
                                <label htmlFor="gearBoxSelect" className="floating-label">Gearbox:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Choose the type of gearbox (transmission) you prefer, such as manual or automatic.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <Form.Select name='gearBox' value={filters.gearBox} onChange={handleChange}>
                                        {Object.entries(carData.gearbox).map(([text, value]) => (
                                            <option key={value} value={value}>{text}</option>
                                        ))}
                                    </Form.Select>
                                   
                                </div>
                            </div>

                            {/* Body Type */}
                            <div className="input-container">
                                <label htmlFor="bodyTypeSelect" className="floating-label">Body:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Select the body style of the car, like sedan, SUV, or hatchback.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <Form.Select name='bodyType' value={filters.bodyType} onChange={handleChange}>
                                        {Object.entries(carData.body).map(([text, value]) => (
                                            <option key={value} value={value}>{text}</option>
                                        ))}
                                    </Form.Select>
                                    
                                </div>
                            </div>

                            {/* Color */}
                            <div className="input-container">
                                <label htmlFor="colorSelect" className="floating-label">Color:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Pick the color of the car you're looking for, like black, white, or red.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <Form.Select name='color' value={filters.color} onChange={handleChange}>
                                        {Object.entries(carData.color).map(([text, value]) => (
                                            <option key={value} value={value}>{text}</option>
                                        ))}
                                    </Form.Select>
                                    
                                </div>
                            </div>

                            {/* Area */}
                            <div className="input-container">
                                <label htmlFor="areaSelect" className="floating-label">Area:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Select the location where you want to find cars, such as a city or region.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <Form.Select name='area' value={filters.area} onChange={handleChange}>
                                        {Object.entries(defaultData.area).map(([text, value]) => (
                                            <option key={value} value={value}>{text}</option>
                                        ))}
                                    </Form.Select>
                                    
                                </div>
                            </div>

                            {/* Deal Type */}
                            <div className="input-container">
                                <label htmlFor="dealTypeSelect" className="floating-label">Deal type:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Choose the type of deal you're interested in, such as buying, leasing, or renting the car.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <Form.Select name='dealType' value={filters.dealType} onChange={handleChange}>
                                        {Object.entries(defaultData.deal_type).map(([text, value]) => (
                                            <option key={value} value={value}>{text}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </div>

                            {/* Total Run */}
                            <div className="input-container">
                                <label htmlFor="totalRunInput" className="floating-label">Maximum Kilometers Run:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Enter the maximum kilometers run. Only cars with equal or fewer kilometers will be sent.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <FloatingLabel controlId="floatingTotalRun" label="Total Run (0 - 5,000,000)">
                                        <Form.Control
                                            id='totalRunInput'
                                            type="number"
                                            placeholder="Total Run"
                                            name='totalRun'
                                            min="0"
                                            max="5000000"
                                            value={filters.totalRun || ''}
                                            onChange={handleChange}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>

{/* Checkup */}
<Form id='checkup-form'>
    <Form.Group className="mb-3" controlId="checkUpCheckbox">
        <Form.Check
            type="checkbox"
            label="Minimum Checkup Date"
            checked={showCheckUpDate}
            onChange={handleCheckUpChange}
        />
    </Form.Group>
    {showCheckUpDate && (
        <Form.Group className="mb-3" controlId="checkUpMonthSelect">
            <label htmlFor="checkupSelect" className="floating-label">Checkup valid until:</label>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Enter the earliest month for the car's last checkup. Cars with checkups from this month or later will be shown.</Tooltip>}
            >
                <InfoCircle className="ms-2 info-icon" />
            </OverlayTrigger>
            <Form.Select name='checkup' value={filters.checkup} onChange={handleChange}>
                {generateCheckUpMonths().map((month) => (
                    <option key={month.value} value={month.value}>{month.text}</option>
                ))}
            </Form.Select>
        </Form.Group>
    )}
</Form>

                            {/* Buttons */}
                            <Row>
                                <Col xs={8} sm={9} md={10} xxl={11} className="mt-3">
                                    <Button variant="primary" className="btn-save" onClick={handleValidation} type='button'>
                                        {isSaving ? (
                                            <>
                                                <Spinner animation="border" size="sm" className="mr-2" />
                                                Saving...
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </Col>
                                <Col xs={4} sm={3} md={2} xxl={1} className="mt-3">
                                    <Button variant="danger" className="btn-delete" onClick={() => setShowDeleteModal(true)}>Delete</Button>
                                </Col>
                            </Row>
                            {errorMessage && <div className="error-message text-center">{errorMessage}</div>}

                            {/* Confirm Modal */}
                            <Modal className="delete-modal" show={showDeleteModal}
                                onHide={() => {
                                    setShowDeleteModal(false);
                                    setIsDeleting(false);
                                }}>
                                <Modal.Header closeButton className='delete-modal-header'>
                                    <Modal.Title>Delete Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you sure you want to delete <b>{filters.filterGroupName}</b> filter?</Modal.Body>
                                <Modal.Footer>
                                    {!isDeleting && (
                                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                            Cancel
                                        </Button>
                                    )}
                                    <Button variant="danger" onClick={handleDelete}>
                                        {isDeleting ? (
                                            <>
                                                <Spinner animation="border" size="sm" className="mr-2" />
                                                Deleting...
                                            </>
                                        ) : (
                                            'Confirm'
                                        )}
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </Accordion.Body>
                    </Accordion.Item>
                </form>
            </div>
        </div>
    );
}

export default FiltersForm;
