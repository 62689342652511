// import React, { useState, useEffect} from 'react';
import NavigationBar from './NavigationBar';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import axios from 'axios';
import './styles/products.scss';
import ssCom from './img/sscom-gray.svg';
import comingSoon from './img/coming_soon-gray.svg';

function ProductsPage() {




    return (
        <main>
            <NavigationBar/>

            <div className='container-fluid' id='products-container'>
                <Row>
                    <Col xs={12} xl={6} lg={6} className="left-column">
                        <p>ss.com</p>
                        <Link to="/filters" className="card-link">
                            <div className="card">
                                <div className="card-body">
                                    <img src={ssCom} alt="ss.com" className="ss-image"/>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={12} xl={6} lg={6} className="right-column">
                        <p>Coming soon...</p>
                        <div className="card">
                            <div className='card-body'>
                                <img src={comingSoon} alt="ss.com" className="coming-soon-image"/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </main>
    )
}

export default ProductsPage;