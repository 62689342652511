// src/components/NavigationBar.jsx

import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './styles/navbar.scss';
import logo from './img/sw_logo-white.svg';
import burgerIcon from './img/burger_menu-white.svg';

function NavigationBar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track user login state
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const isMobile = useMediaQuery({ query: '(max-width: 1199px)' });

  useEffect(() => {
    const fetchAuthStatus = async () => {
      setIsLoading(true); // Set loading state
      try {
        const res = await fetch('/api/auth/status');
        const data = await res.json();
        setIsLoggedIn(data.isLoggedIn);
      } catch (error) {
        console.error('Error fetching authentication status:', error);
      } finally {
        setIsLoading(false); // Reset loading state
      }
    };

    fetchAuthStatus(); 
  }, []);  

  const AuthLinks = () => (
    <>
      {isMobile ? (
        <>
          <Link to='/profile' className="nav-link">Profile</Link>
          <span className='horizontal-divider'></span>
          <a href="/api/auth/signout" className="nav-link">Sign out</a>
        </>
      ) : (
        <NavDropdown align="end" title="Account" id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to='/profile'>Profile</NavDropdown.Item>
          <span className='horizontal-divider'></span>
          <NavDropdown.Item as="a" href="/api/auth/signout">Sign out</NavDropdown.Item>
        </NavDropdown>
      )}
    </>
  );

  const GuestLinks = () => (
    <>
      {isMobile ? (
        <>
          <Link to='/login' className="nav-link">Login</Link>
          <span className='horizontal-divider'></span>
          <Link to='/signup' className="nav-link">Sign up</Link>
        </>
      ) : (
        <NavDropdown align="end" title="Account" id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to='/login'>Login</NavDropdown.Item>
          <span className='horizontal-divider'></span>
          <NavDropdown.Item as={Link} to='/signup'>Sign up</NavDropdown.Item>
        </NavDropdown>
      )}
    </>
  );

  return (
    <Navbar expand="xl" className="custom-navbar">
      <Container fluid>
        <Navbar.Brand>
          <Link to='/' className="nav-link" aria-label="Homepage">
            <img src={logo} alt="StalkerWind" className="nav-logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-xl-none">
          <img src={burgerIcon} alt="Menu" style={{ width: '24px', height: '24px' }} />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"> {/* Left-aligned navigation */}
            {isLoggedIn && (
              <Link to='/products' className="nav-link">Products</Link>
            )}
            {/* Add Feedback link */}
            <Link to='/feedback' className="nav-link">Feedback</Link>
          </Nav>
          <Nav> {/* Right-aligned navigation */}
            {isLoading ? ( // Show loading state
              <span className="nav-link">Loading...</span>
            ) : (
              isLoggedIn ? <AuthLinks /> : <GuestLinks />
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

}

export default NavigationBar;
